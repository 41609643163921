@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl mb-5;
  }

  h2 {
    @apply text-2xl mb-4;
  }

  h3 {
    @apply text-xl  mb-3;
  }

  p {
    @apply mb-3;
  }
}
