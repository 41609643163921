@import './tailwind.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'swiper/scss';
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

swiper-slide {
  height: auto;
}

.swiper-button-prev:after {
  content: '';
}

.swiper-button-next:after {
  content: '';
}

// .swiper-wrapper {
//   .swiper-slide {
//     &.swiper-slide-visible {
//       opacity: 0.3;

//       &:not(:last-child) {
//         opacity: 1;
//       }
//     }
//   }
// }

.overlay-backdrop {
  background: hsla(0, 0%, 0%, 0.2);
  backdrop-filter: blur(4px);
}

svg path {
  stroke-width: 1.5;
}

.bbit-inner-html {
  p {
    @apply mb-4;
  }
  ul {
    @apply list-disc ml-4 space-y-4;
  }
  a {
    @apply underline;
  }
}
